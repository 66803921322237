import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import SelectGautra from './select-gautra';
import SelectVillage from './select-village';
import { updateFamilyField } from '../../actions/familyTreeAction';
import LoadingSpinner from '../loader';
import Modal from '../../Utilities/Modal';
import { getLoggedInUserId } from '../../actions/authAction';

const UpdateFamilyDetails = ({ gautra, village, family_id, onClose, updatePage, subcaste }) => {
    const [newGautraObj, setNewGautraObj] = useState({
        gautra,
        subcaste
    });
    const [newVillage, setNewVillage] = useState(village);
    const [nodeId, setNodeId] = useState('');
    const [isLoading, setLoader] = useState(false)

    useEffect(() => {
        setNodeId(getLoggedInUserId());
    }, [])


    const updateDetails = async (data) => {
        setLoader(true);
        console.log("data to be updated", data)
        const response = await updateFamilyField(data);
        console.log('response after updating data', response);
        updatePage();
        setLoader(false);
    }
    const details = (
        <Modal toggle={onClose}>
            <Card>
                <CardHeader>Edit Family Details</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <>Gautra:</>
                            <SelectGautra setGautraObj={setNewGautraObj} gautraObj={newGautraObj} disabled={false} />
                            <Button
                                status="Success"
                                type="button"
                                shape="SemiRound"
                                style={{ margin: '10px', display: 'block' }}
                                onClick={() => {
                                    const fieldsToChange = [{ field: 'gautra', new_value: newGautraObj?.gautra, old_value: gautra }]
                                    if (newGautraObj?.subcaste) {
                                        fieldsToChange.push({ field: 'subcaste', new_value: newGautraObj?.subcaste ?? '', old_value: subcaste })
                                    }
                                    const data = {
                                        family_id,
                                        field: 'gautra',
                                        old_value: gautra,
                                        new_value: newGautraObj?.gautra,
                                        // new_subcaste:newGautraObj?.subcaste??'',
                                        fields_to_change: fieldsToChange,
                                        node_id: nodeId
                                    }
                                    updateDetails(data)
                                }
                                }
                            >
                                Update</Button>
                        </Col>

                        <Col>
                            <>Village:</>
                            <SelectVillage setVillage={setNewVillage} village={newVillage} />
                            <Button
                                status="Success"
                                type="button"
                                shape="SemiRound"
                                style={{ margin: '10px', display: 'block' }}
                                onClick={() => {
                                    const data = {
                                        family_id,
                                        field: 'village',
                                        old_value: village,
                                        new_value: newVillage,
                                        node_id: nodeId
                                    }
                                    updateDetails(data)
                                }
                                }
                            >
                                Update</Button>
                        </Col>
                    </Row>

                </CardBody>

                <CardFooter>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>

                        <Button status="Danger" type="button" shape="SemiRound"
                            style={{ margin: '10px', display: 'block' }}
                            onClick={onClose}
                        >
                            Close
                        </Button>

                    </Row>
                </CardFooter>
            </Card>
        </Modal>
    )
    return <>{isLoading ? <LoadingSpinner message="Updating..." /> : details}</>;
}

export default UpdateFamilyDetails;
